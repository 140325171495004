import React, { useState } from 'react';
import ClientesService from 'src/services/Clientes';
import { message } from 'antd';
import { useNavigate } from "react-router-dom";

const AddClient = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nombres: '',
        tipoIdentificacion: '',
        nroIdentificacion: '',
        correo: '',
        direccion: '',
        celular: '',
    });
    const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    let valid = true;

    if (!formData.nombres) {
      formErrors.nombres = 'El campo nombres es obligatorio';
      valid = false;
    }

    if (!formData.tipoIdentificacion) {
      formErrors.tipoIdentificacion = 'El tipo de identificación es obligatorio';
      valid = false;
    }

    if (!formData.nroIdentificacion) {
      formErrors.nroIdentificacion = 'El número de identificación es obligatorio';
      valid = false;
    }

    if (!formData.correo) {
      formErrors.correo = 'El correo es obligatorio';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.correo)) {
      formErrors.correo = 'El correo no es válido';
      valid = false;
    }

    if (!formData.direccion) {
      formErrors.direccion = 'La dirección es obligatoria';
      valid = false;
    }

    if (!formData.celular) {
      formErrors.celular = 'El celular es obligatorio';
      valid = false;
    } else if (!/^[0-9]+$/.test(formData.celular)) {
      formErrors.celular = 'El celular debe contener solo números';
      valid = false;
    }

    setErrors(formErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Aquí puedes enviar los datos al backend o hacer alguna otra acción
        
        let data = {
            names: formData.nombres,
            address: formData.direccion,
            tipo: formData.tipoIdentificacion,
            identificacion: formData.nroIdentificacion,
            email: formData.correo,
            phone: formData.celular,
            chkTerm: 1
        }
        console.log('Formulario válido:', data);
        ClientesService.save(data).then(resp => {
            message.success(`Cliente agregado con éxito`); 
            setTimeout(() => {                          
                navigate('/clientes');
            }, 2000);
        })
    }
  };

  return (
    <div className="container mt-2">
      <h2 className='text-center'>Registro de Cliente</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nombres">Nombres</label>
          <input
            type="text"
            className={`form-control ${errors.nombres ? 'is-invalid' : ''}`}
            id="nombres"
            name="nombres"
            value={formData.nombres}
            onChange={handleChange}
          />
          {errors.nombres && <div className="invalid-feedback">{errors.nombres}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="tipoIdentificacion">Tipo de Identificación</label>
          <select
            className={`form-control ${errors.tipoIdentificacion ? 'is-invalid' : ''}`}
            id="tipoIdentificacion"
            name="tipoIdentificacion"
            value={formData.tipoIdentificacion}
            onChange={handleChange}
          >
            <option value="">Selecciona...</option>
            <option value="CC">Cédula de ciudadania</option>
            <option value="NIT">Número de identificación tributaria</option>
            <option value="NIP">Número de identificación personal</option>
            <option value="TI">Tarjeta de identidad</option>
            <option value="PAP">Pasaporte</option>
          </select>
          {errors.tipoIdentificacion && <div className="invalid-feedback">{errors.tipoIdentificacion}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="nroIdentificacion">Número de Identificación</label>
          <input
            type="text"
            className={`form-control ${errors.nroIdentificacion ? 'is-invalid' : ''}`}
            id="nroIdentificacion"
            name="nroIdentificacion"
            value={formData.nroIdentificacion}
            onChange={handleChange}
          />
          {errors.nroIdentificacion && <div className="invalid-feedback">{errors.nroIdentificacion}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="correo">Correo</label>
          <input
            type="email"
            className={`form-control ${errors.correo ? 'is-invalid' : ''}`}
            id="correo"
            name="correo"
            value={formData.correo}
            onChange={handleChange}
          />
          {errors.correo && <div className="invalid-feedback">{errors.correo}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="direccion">Dirección</label>
          <input
            type="text"
            className={`form-control ${errors.direccion ? 'is-invalid' : ''}`}
            id="direccion"
            name="direccion"
            value={formData.direccion}
            onChange={handleChange}
          />
          {errors.direccion && <div className="invalid-feedback">{errors.direccion}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="celular">Celular</label>
          <input
            type="text"
            className={`form-control ${errors.celular ? 'is-invalid' : ''}`}
            id="celular"
            name="celular"
            value={formData.celular}
            onChange={handleChange}
          />
          {errors.celular && <div className="invalid-feedback">{errors.celular}</div>}
        </div>

        <button type="submit" className="btn btn-primary my-3">Registrar</button>
      </form>
    </div>
  );
}

export default AddClient;