import React, { useState, useEffect } from 'react';
import VehiculosService from "src/services/VehiculosService";
import { Table, Space } from 'antd';
 
const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Enlace',
      dataIndex: 'url',
      key: 'url'
    },
    {
        title: 'Acción',
        key: 'action',
        render: (_, record) => (
          <>
            {/* <Space size="middle">
              <a href="#">Eliminar</a>
            </Space> */}
            <Space size="middle">
              <a href={record.url} target='_blank'>Ver</a>
            </Space>
          </>          
        ),
    },
];

async function validarPDF(url) {
  try {
    const response = await fetch(url, { method: 'HEAD' }); // Usamos HEAD para evitar descargar el archivo completo
    if (response.ok) {
      console.log('El PDF existe.');
      return true;
    } else {
      console.log('El PDF no existe o no es accesible.');
      return false;
    }
  } catch (error) {
    console.error('Error al verificar el PDF:', error);
    return false;
  }
}

const Archivos = ({car_id, placa}) =>{
  const [archivos, setArchivos] = useState([]);
  let _files = []

  const getCar = () =>{

    validarPDF(`https://intranet.triplo.com.co/pdfs/Acta_de_recepcion_${placa}.pdf`).then(resp =>{
      _files.push({
        key: 98,
        name: 'Acta de recepción',
        url: `https://intranet.triplo.com.co/pdfs/Acta_de_recepcion_${placa}.pdf`,
      })
    })
    validarPDF(`https://intranet.triplo.com.co/pdfs/Tarjeta_de_propiedad_${placa.toUpperCase()}.pdf`).then(resp =>{
      _files.push({
        key: 99,
        name: 'Tarjeta de propiedad',
        url: `https://intranet.triplo.com.co/pdfs/Tarjeta_de_propiedad_${placa.toUpperCase()}.pdf`,
      })
    })
    
    VehiculosService.getArchivos(car_id).then(resp => {
      let _resp = resp.data ? resp.data : []
      _files = [..._files, ..._resp]
      setArchivos(_files)
    })
  }

  useEffect(() => {
    getCar();
  }, [])

  return(
    <Table dataSource={archivos} columns={columns}  locale={{ emptyText: 'El vehículo no tiene archivos agregados.' }} pagination={{
        defaultPageSize: 5,
    }}/>
  );
}

export default Archivos;