import React ,{ useEffect, useState } from 'react';
import { CRow } from "@coreui/react";
import { Col } from "antd";
import { useNavigate } from 'react-router-dom';
import ImpuestoService from "src/services/ImpuestoService";
import moment from 'moment';

const numberFormat = (value) => new Intl.NumberFormat().format(value);

const Impuestos = () => {
    const navigate = useNavigate();
    const [impuestos, setImpuestos] = useState({});

    const modificar = (impuesto, impuestoTitle, valor, fechap) => {
        navigate('/impuestos/modificar', { state: { impuesto, impuestoTitle, valor, fechap } });
    }
    const formatearFecha = (fecha) => {
        const [dia, mes, año] = fecha.split('-');
        const fechaObj = new Date(Date.UTC(año, mes - 1, dia));
        return fechaObj.toLocaleDateString('es-ES', { day: 'numeric', month: 'short', year: 'numeric' });
    };

    useEffect(() => {
        ImpuestoService.getAll().then(resp =>{
            let _data = resp.data[0];
            setImpuestos(_data)
        })
    }, [])
    return(
        <>
            <CRow>
                <Col span={24} className="text-center">
                    <h3>Impuestos</h3>
                </Col>
            </CRow>
            <CRow>
                <Col span={8}>
                    <div className="form-group">
                        <h4 className="mb-0">IVA</h4>
                        <button className="btn btn-primary btn-big" type="button" onClick={ () => modificar('iva', 'IVA', impuestos.iva, impuestos.ivaFecha) }>
                            <p>{ impuestos.iva ? "$ "+numberFormat(impuestos.iva) : "$ 0"}</p>
                            <p>{ impuestos.ivaFecha ? moment(impuestos.ivaFecha, 'DD-MM-YYYY').format('DD MMM YYYY') : ""}</p>
                        </button>
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-group">
                    <h4 className="mb-0">Retefuente</h4>
                        <button className="btn btn-primary btn-big" type="button" onClick={ () => modificar('retefuente', 'Retefuente', impuestos.retefuente, impuestos.retefuenteFecha) }>
                            <p>{ impuestos.retefuente ? "$ "+numberFormat(impuestos.retefuente) : "$ 0"}</p>
                            <p>{ impuestos.retefuenteFecha ? moment(impuestos.retefuenteFecha, 'DD-MM-YYYY').format('DD MMM YYYY') : ""}</p>
                        </button>
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-group">
                        <h4 className="mb-0">Ipoconsumo</h4>
                        <button className="btn btn-primary btn-big" type="button" onClick={ () => modificar('ipoconsumo', 'Ipoconsumo', impuestos.ipoconsumo, impuestos.ipoconsumoFecha) }>
                            <p>{ impuestos.ipoconsumo ? "$ "+numberFormat(impuestos.ipoconsumo) : "$ 0"}</p>
                            <p>{ impuestos.ipoconsumoFecha ? moment(impuestos.ipoconsumoFecha, 'DD-MM-YYYY').format('DD MMM YYYY') : ""}</p>
                        </button>
                    </div>                    
                </Col>
            </CRow>
            <CRow>
                <Col span={8}>
                    <div className="form-group">
                        <h4 className="mb-0">Reteica</h4>
                        <button className="btn btn-primary btn-big" type="button" onClick={ () => modificar('reteica', 'Reteica', impuestos.reteica, impuestos.reteicaFecha) }>
                            <p>{ impuestos.reteica ? "$ "+numberFormat(impuestos.reteica) : "$ 0"}</p>
                            <p>{ impuestos.reteicaFecha ? moment(impuestos.reteicaFecha, 'DD-MM-YYYY').format('DD MMM YYYY') : ""}</p>
                        </button>
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-group">
                        <h4 className="mb-0">ICA</h4>
                        <button className="btn btn-primary btn-big" type="button" onClick={ () => modificar('ica', 'ICA', impuestos.ica, impuestos.icaFecha) }>
                            <p>{ impuestos.ica ? "$ "+numberFormat(impuestos.ica) : "$ 0"}</p>
                            <p>{ impuestos.icaFecha ? moment(impuestos.icaFecha, 'DD-MM-YYYY').format('DD MMM YYYY') : ""}</p>
                        </button>
                    </div>                    
                </Col>
                <Col span={8}>
                    <div className="form-group">
                        <h4 className="mb-0">ReteIVA</h4>
                        <button className="btn btn-primary btn-big" type="button" onClick={ () => modificar('reteiva', 'ReteIVA', impuestos.reteiva, impuestos.reteivaFecha) }>
                            <p>{ impuestos.reteiva ? "$ "+numberFormat(impuestos.reteiva) : "$ 0"}</p>
                            <p>{ impuestos.reteivaFecha ? moment(impuestos.reteivaFecha, 'DD-MM-YYYY').format('DD MMM YYYY') : ""}</p>
                        </button>
                    </div>                    
                </Col>
            </CRow>
        </>
    )
}
export default Impuestos;