import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slider } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: true,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
};

const Credito = () => {
    const navigate = useNavigate();
    const [price, setPrice] = useState(138); // Estado para el precio
    const [financePercent, setFinancePercent] = useState(65); // Estado para el porcentaje financiado
    const [currentStep, setCurrentStep] = useState(1); // Paso actual del formulario

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handleFinancePercentChange = (newValue) => {
        setFinancePercent(newValue);
    };

    const volver = () => {
        navigate('/car-market');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Precio acordado:", price);
        console.log("Porcentaje financiado:", financePercent);
    };

    const nextButton = () => {
        setCurrentStep(currentStep + 1);
    };

    const getStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="form-group">
                        <label>2. Seleccione el proveedor del crédito</label>
                        <div className="d-flex">
                            <button className="btn btn-primary btn2 w-100" onClick={ nextButton }>TRIPLO</button>
                            <button className="btn btn-primary btn2 w-100">Otra entidad</button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <>
                        <div className="form-group">
                            <label>Proveedor del crédito</label>
                            <div className="d-flex">
                                <button className="btn btn-primary btn3 w-100">TRIPLO</button>
                                <button className="btn btn-primary btn3 btn_disabled w-100">Otra entidad</button>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>1. ¿El cliente llenó el formulario?</label>
                            <div className="d-flex">
                                <button className="btn btn-primary btn3 w-100">SÍ</button>
                                <button className="btn btn-primary btn3 btn_disabled w-100">NO</button>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>2. Agregue la carta de aprobación</label>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Arrastra o selecciona un archivo</p>
                            </Dragger>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="car-finance-form contado">
            <h2>LNL459 (placa del carro) { currentStep }</h2>

            <form className="car-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>1. Indique el precio acordado del vehículo</label>
                    <input
                        type="number"
                        value={price}
                        onChange={handlePriceChange}
                        min="0"
                        placeholder="Ingrese el precio"
                        style={{ width: "100%" }}
                    />
                </div>

                {getStep()}

                <div className="form-actions">
                    <button type="button" onClick={nextButton} className="save-btn" style={{ backgroundColor: "orange" }}>
                        Guardar
                    </button>
                    <button type="button" className="cancel-btn" style={{ backgroundColor: "lightgray" }} onClick={volver}>
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Credito;
