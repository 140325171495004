import React, { useState, useEffect } from 'react';
import { Slider } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useNavigate, useParams  } from "react-router-dom";
import CarMarketService from 'src/services/CarMarketService';

const moneyFormat = (value) => new Intl.NumberFormat().format(value);
const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: true,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
};

const ContadoCredito = () => {
    const navigate = useNavigate();
    const [price, setPrice] = useState(); // Estado para el precio
    const [financePercent, setFinancePercent] = useState(); // Estado para el porcentaje financiado
    const [currentStep, setCurrentStep] = useState(1); // Paso actual del formulario
    const [formData, setFormData] = useState({
        abonos: 1,
        tipoSeparacion: '',
        responsabilidadGastos: '',
        precioVehiculo: '',
        precio: '',
        clase: '',
        marca: '',
        tipo: '',
        modelo: '',
        color: '',
        placa: '',
        chasis: '',
        motor: '',
        linea: '',
        servicio: '',
        abono1: '',
        fechaAbono1: '',
        abono2: '',
        fechaAbono2: '',
        abono3: '',
        fechaAbono3: '',
        fechaContrato: '',
        valor_credito: '',
        porc_credito: 0
    });
    var { id } = useParams();

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handleFinancePercentChange = (newValue) => {
        setFinancePercent(newValue);
    };

    const volver = () => {
        navigate('/car-market');
    }

    const handleSubmit = (e) => {
        e.preventDefault();

    };

    const nextButton = () => {
        setCurrentStep(currentStep + 1);
    };

    const calcularPorc = (e) =>{
        e.preventDefault();
        let _porc = (parseInt(e.target.value) * 100 ) / formData.precio;
        setFormData({ ...formData, ["porc_credito"]: Math.round(_porc) });
    }

    const getStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="row">
                        <div className='col-6'>
                            <div className="form-group">
                                <label>2. Indique el valor del crédito</label>                        
                                <input
                                    type="text"
                                    value={ formData.valor_credito }
                                    onChange={ calcularPorc }
                                />
                            </div>
                        </div>
                        <div className='col-6 text-center'>
                            <Slider
                                value={ formData.porc_credito }
                                defaultValue={0}
                                min={0}
                                max={100}
                                tooltip={{ formatter: (val) => `${val}%` }}
                            />
                            <p>{formData.porc_credito}%</p>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="form-group">
                        <label>2. Seleccione el proveedor del crédito</label>
                        <div className="d-flex">
                            <button className="btn btn-primary btn2 w-100" onClick={ nextButton }>TRIPLO</button>
                            <button className="btn btn-primary btn2 w-100">Otra entidad</button>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <>
                        <div className="form-group">
                            <label>Proveedor del crédito</label>
                            <div className="d-flex">
                                <button className="btn btn-primary btn3 w-100">TRIPLO</button>
                                <button className="btn btn-primary btn3 btn_disabled w-100">Otra entidad</button>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>1. ¿El cliente llenó el formulario?</label>
                            <div className="d-flex">
                                <button className="btn btn-primary btn3 w-100">SÍ</button>
                                <button className="btn btn-primary btn3 btn_disabled w-100">NO</button>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>2. Agregue la carta de aprobación</label>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Arrastra o selecciona un archivo</p>
                            </Dragger>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        CarMarketService.getById(id).then(data =>{
         let _data = data.data;
         if(_data.length > 0){
          _data = _data[0]
          let _car = {
            abonos: '',
            tipoSeparacion: '',
            responsabilidadGastos: '',
            precioVehiculo: moneyFormat(_data.price),
            precio: (_data.price),
            clase: _data.vehicle_body_type,
            marca: _data.make,
            tipo: _data.vehicle_type,
            modelo: _data.model,
            color: _data.color,
            placa: _data.plate,
            chasis: _data.chasis,
            motor: _data.motor,
            linea: _data.version,
            servicio: '',
            separacion: '',
            fecha_separacion: '',
            banco_separacion: '',
            fecha_contrato: '',
          }
          setFormData(_car)
         }
        })
      }, [null])

    return (
        <div className="car-finance-form contado">
            <h2>{ formData.placa }</h2>

            <form className="car-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>1. Indique el precio acordado del vehículo</label>
                    <input
                        type="number"
                        value={formData.precio}
                        onChange={handlePriceChange}
                        min="0"
                        placeholder="Ingrese el precio"
                        style={{ width: "100%" }}
                    />
                </div>

                {getStep()}

                <div className="form-actions">
                    <button type="button" onClick={nextButton} className="save-btn" style={{ backgroundColor: "orange" }}>
                        Guardar
                    </button>
                    <button type="button" className="cancel-btn" style={{ backgroundColor: "lightgray" }} onClick={volver}>
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ContadoCredito;
