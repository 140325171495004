import React, { useState, useEffect } from 'react';
import './Styles.scss'; // Importa el archivo de estilos
import { useNavigate } from "react-router-dom";
import { CCol, CRow } from '@coreui/react';
import CarMarketService from 'src/services/CarMarketService';
import ClientesService from 'src/services/Clientes';
import { AutoComplete } from 'antd';

function IndexCont() {
  const navigate = useNavigate();

  // Estado para los inputs
  const [plate, setPlate] = useState('');
  const [buyerId, setBuyerId] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [clients, setClients] = useState([]);
  const [options, setOptions] = useState([]);

  // Estado para controlar si los botones están habilitados
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  function getCar(){
    let _filter = 'search=' + plate;
    return CarMarketService.getAll(_filter).then(resp => {
        let _data = resp.data;
        if (_data.length > 0) {
            let id = _data[0].id;
            localStorage.setItem("id_comprador", buyerId);
            localStorage.setItem("id_vendedor", sellerId);
            return id; // Retorna el ID dentro del .then
        } else {
            throw new Error("No data found");
        }
    });
  }
  const contado = async () => {
    const id = await getCar();
    navigate('/car-market/contado/'+id);
  };

  const contadoCredito = async () =>{
    const id = await getCar();
    navigate('/car-market/contado-credito/'+id);
  }

  const credito = async () =>{
    const id = await getCar();
    navigate('/car-market/credito/'+id);
  }

  const partePago = async () =>{
    const id = await getCar();
    navigate('/car-market/parte-pago/'+id);
  }

  useEffect(() => {
    ClientesService.getAllClients().then(resp =>{
      let _array = resp.data;
      const nuevoArray = _array.map(item => ({
        label: item.identification,
        value: item.identification
      }));
      setClients(nuevoArray)
    })
  }, [])
  // Efecto para verificar si los campos están completos y habilitar botones
  useEffect(() => {
    //const isPlateValid = plate.length === 6 && /^[A-Z0-9]+$/.test(plate);
    if (plate && buyerId && sellerId) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [plate, buyerId, sellerId]);

  // Funciones para manejar el cambio de valor de los inputs
  const handlePlateChange = (e) => {
    setPlate(e.target.value.toUpperCase()); // Convertir siempre a mayúsculas
  };

  const handleBuyerIdChange = (e) => {
    setBuyerId(e);
  };

  const handleSellerIdChange = (e) => {
    setSellerId(e);
  };

  return (
    <CRow>
      <CCol xs={12} className="text-center">
        <div className="text-center app-container">
          <div className="input-container">
            <input
              type="text"
              placeholder="Placa del vehículo"
              className="input-field text-uppercase"
              value={plate}
              onChange={handlePlateChange}
              maxLength={6} // Limitar a 6 caracteres
            />
            <AutoComplete
              options={ clients }
              onSelect={handleBuyerIdChange}
              style={{
                width: 300,
                height:60,
                border: 'none'
              }}
              className="form-control"
              placeholder="N° de identificación del comprador"
            />
            <AutoComplete
              options={ clients }
              style={{
                width: 300,
                height:60,
                border: 'none'
              }}
              onSelect={handleSellerIdChange}
              className="form-control"
              placeholder="N° de identificación del vendedor"
            />
          </div>          
        </div>
      </CCol>
      <CCol xs={12}>
        <div className="text-center app-container">
          <div className="button-container">
            <button
              className="custom-button"
              onClick={contado}
              disabled={isButtonDisabled} 
            >
              Contado
            </button>
            <button className="custom-button" onClick={ contadoCredito } disabled={isButtonDisabled}>Contado y Credito</button>
            <button className="custom-button" onClick={ credito } disabled={isButtonDisabled}>Credito</button>
            <button className="custom-button" onClick={ partePago } disabled={isButtonDisabled}>Carro parte de pago</button>
          </div>
        </div>
      </CCol>
    </CRow>
  );
}

export default IndexCont;