import API from "./Config";
import ENDPOINTS from "./Enpoints";

const TramitesService = {
    getAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("tramites?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRAMITES, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    update: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.TRAMITES, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    })
}

export default TramitesService;
