import React ,{ useEffect } from 'react';
import { CRow } from "@coreui/react";
import { Col, Form, Input, Button, message } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import ImpuestoService from "src/services/ImpuestoService";
import moment from 'moment';

const ImpuestoModificar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [formNew] = Form.useForm();

    const { impuesto, impuestoTitle, valor, fechap } = location.state || {};

    const saveImpuesto = (values) =>{
        //console.log(values.fecha_pago)
        const formattedDate = values.fecha_pago ? moment(values.fecha_pago).format('DD-MM-YYYY') : '';
        let data = {
            "impuesto": impuesto,
            "valor": values.cantidad,
            "fecha": formattedDate
        }
        //console.log(data)
        ImpuestoService.update(data).then(() =>{
            message.success("Impuesto modificado. Redireccionado...")
            setTimeout(() => {
                navigate('/impuestos');
            }, 2000);            
        })
    }
    const cancelar = () =>{
        navigate('/impuestos');
    }

    useEffect(() => {
        console.log(valor, fechap)
        let _data = {
            cantidad : valor, 
            fecha_pago: fechap ?  moment(fechap, 'DD-MM-YYYY').format('YYYY-MM-DD') : null
        }
        formNew.setFieldsValue(_data); 
    }, [])

    return(
        <div className="container">
            <Form onFinish={ saveImpuesto } autoComplete="off" layout="vertical" form={formNew}>
                <CRow>
                    <Col span={24} className="text-left">
                        <h4>Modificar {impuestoTitle || ''}</h4>
                    </Col>
                </CRow>
                <CRow className="mt-3">
                    <Col span={12}>
                        <Form.Item label="Valor" name="cantidad" 
                            rules={[
                                {
                                    required: true,
                                    message: 'La cantidad es requerida!',
                                },
                            ]}>
                            <Input placeholder="Ingrese la cantidad"/>
                        </Form.Item>                   
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Fecha pago" name="fecha_pago" 
                            rules={[
                                {
                                    required: true,
                                    message: 'La fecha de pago es requerida!',
                                },
                            ]}>
                            <Input type='date' />
                        </Form.Item>                   
                    </Col>
                </CRow>
                <CRow className="mt-3">
                    <Col>
                        <button className="btn btn-secondary me-3" type="button" onClick={ cancelar }>
                            Cancelar
                        </button>
                        <Button type="primary" className="btn btn-primary" htmlType="submit">
                            Modificar
                        </Button>
                    </Col>
                </CRow>
            </Form>
        </div>
    )
}
export default ImpuestoModificar;