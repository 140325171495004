import React from 'react'
import { CCol, CFooter, CRow } from '@coreui/react'
import { useAuthUser } from 'react-auth-kit';
const currentDate = new Date().getFullYear();
const AppFooter = () => {
  const authUser = useAuthUser();
  return (
    <CFooter>
      <CRow>
        <CCol>
          <span>{ authUser().email }</span>
        </CCol>
        <CCol>
          <div className='text-end'>        
            <a href="https://triplo.com.co" target="_blank" rel="noopener noreferrer">
              Triplo
            </a>
            <span className="ms-1">&copy; { currentDate } Todos los derechos reservados.</span>
          </div>
        </CCol>
      </CRow>      
    </CFooter>
  )
}

export default React.memo(AppFooter)
