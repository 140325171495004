import React, {useState, useEffect} from 'react'
import { Col, Row, Modal, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component'
import Moment from 'moment'
import BonosService from 'src/services/BonosService';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import { EyeOutlined} from '@ant-design/icons';

const Alianzas = () => {
    const columns = [
        {
            name: 'Alianza',
            selector: rowData => rowData.nombre
        }, 
        {
            name: 'Total consecutivos',
            selector: rowData => rowData.total_consecutivos
        },
        {
            name: 'Consecutivos activos',
            selector: rowData => rowData.consecutivos_activos
        },
        {
            name: 'Acciones',
            selector: rowData => {
              return <div className='d-flex justify-content-between'>
                  <div className='text-center cc1' onClick={ () => showData(rowData) }>
                      <EyeOutlined className='iconAprobar' title='Ver'/>
                  </div>
              </div>           
            }
        }
    ];
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const authUser = useAuthUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAlianzaOpen, setIsModalAlianzaOpen] = useState(false);
    const [item, setItem] = useState({});
    const [filters, setFilters] = useState({
        "search" : ''
    })
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const showData = (data) =>{
        setItem(data)
        setIsModalOpen(true);
    } 
    const handlePageChange = page => {
        getData(page);
        setPage(page);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleAlianzaOk = () => {
        //Crear
        //setIsModalAlianzaOpen(false);
        form
        .validateFields()
        .then((values) => {
        console.log(values)        //
        let data = {
            "nombre": values.nombre,
            "descripcion": values.descripcion,
            "vencimiento": values.vencimiento
        }
        BonosService.saveAlianza(data).then(resp =>{
            if(resp && resp.message.code === 200){
                let consecutivos = values.consecutivos.split(','); 
                let alianza_id = resp.message.data.data;
                data = {
                    "alianza_id": alianza_id,
                    "items": consecutivos
                }
                BonosService.saveConsecutivos(data).then(resp_c =>{
                    console.log(resp_c)
                    form.resetFields();
                    getData(1);
                    setIsModalAlianzaOpen(false);
                })
            }
        })
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    };
    const handleAlianzaCancel = () => {
        //borrar formulario y cerrar
        setIsModalAlianzaOpen(false);
    };
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getData(page);    
        setLoading(false)
    };

    const getData = (ppage) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        BonosService.getAlianzas(ppage,limit,skip,_filter).then(resp =>{
            let _data = resp.data;
            setTotalRows(resp.total);
            setData(_data);
            setLoading(false);
        })
    }

    const onFinish = (values) =>{}

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getData(page, limit, skip);
    }, []);

    return(
        <Row>
            <Modal title="Ver Alianza" open={isModalOpen} cancelText="Cerrar" onCancel={handleOk} onOk={handleOk} okText={"Aceptar"}>
                <Row>
                    <Col span={24}>
                        <div className='form-group'>
                            <label>Nombre</label>
                            <input className='form-control' type='text' value={item.nombre} disabled />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='form-group'>
                            <label>Descripcion</label>
                            <input className='form-control' type='text' value={item.descripcion} disabled />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='form-group'>
                            <label>Total consecutivos</label>
                            <input className='form-control' type='text' value={item.total_consecutivos} disabled />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='form-group'>
                            <label>Consecutivos disponibles</label>
                            <input className='form-control' type='text' value={item.consecutivos_activos} disabled />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className='form-group'>
                            <label>Consecutivos usados</label>
                            <input className='form-control' type='text' value={item.total_consecutivos - item.consecutivos_activos} disabled />
                        </div>
                    </Col>
                </Row>
            </Modal>
            <Modal title="Crear Alianza" open={isModalAlianzaOpen} cancelText="Cancelar" onCancel={handleAlianzaCancel} onOk={handleAlianzaOk} okText={"Crear"}>
                <Form  form={form} name="form_alianza" onFinish={onFinish} layout='vertical'>
                    <Row>                    
                        <Col span={24}>
                            <Form.Item label="Nombre" name="nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    }
                                ]}>
                                <Input type='text' placeholder='Nombre' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Descripción" name="descripcion">
                                <Input type='text' placeholder='Descripción (opcional)' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Vencimiento (dias)" name="vencimiento">
                                <Input type='number' placeholder='Ej: 20' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Consecutivos" name="consecutivos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    }
                                ]}>
                                <Input type='text' placeholder='Ejemplo: TN094,AF811,JP513' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Col span={24} className='text-center my-4'>
                <h3>Listado de Alianzas</h3>
            </Col>
            <Col span={24} className='text-end'>
                <Link onClick={ () => setIsModalAlianzaOpen(true) } className='btn btn-primary'>Crear Alianza</Link>
            </Col>
            <Col span={24} className='my-4'>
                <DataTable
                    noDataComponent={"No se han encontrado resultados"}
                    columns={ columns }
                    data={data}
                    progressPending={loading}
                    pagination 
                    paginationServer
                    responsive             
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            </Col>
        </Row>
    )
}

export default Alianzas;
