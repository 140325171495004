import React, {useState, useEffect} from 'react'
import { Col, Row, Form, Input, Modal, Select, message } from 'antd';
import { Link } from 'react-router-dom';
import { CRow } from '@coreui/react';
import DataTable from 'react-data-table-component'
import Moment from 'moment'
import TramitesService from 'src/services/TramitesService';
import ActasService from 'src/services/ActasService';
import { useAuthUser } from 'react-auth-kit';
import { FormOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const Index = () => {
    const columns = [
        {
            name: 'Fecha',
            selector: rowData => Moment(rowData.created_at).format("DD/MM/YYYY h:mm:ss a")
        }, 
        {
            name: 'Estado',
            selector: rowData => rowData.status
        },
        {
            name: 'Proveedor',
            selector: rowData => rowData.proveedor
        },
        {
            name: 'Vehiculo',
            selector: rowData => rowData.vehicle
        },
        {
            name: 'Placa',
            selector: rowData => rowData.plate
        },
        {
            name: 'Acciones',
            selector: rowData => {
              return <div className='d-flex justify-content-between'>
                    <div className='text-center cc1' onClick={ () => showData(rowData) }>
                        <FormOutlined className='iconCameraTable2' title='Editar'/>
                    </div>
              </div>           
            }
        }
    ];
    const marks = {
        0: 'Recolección de documentos',
        1: 'Enviado a traspaso',
        6: 'Radicado',
        3: 'Aprobado',
        5: 'Rechazado',
        4: 'Listado para entrega'
    };
    const _marks = [
        'Recolección de documentos',
        'Enviado a traspaso',
        '',
        'Aprobado',
        'Listado para entrega',
        'Rechazado',
        'Radicado'
    ]
    const points = [
        { x: 50, y: 70, label: 'R. de documentos' },
        { x: 200, y: 70, label: 'Enviado a traspaso' },
        { x: 350, y: 70, label: 'Radicado' },
        { x: 500, y: 20, label: 'Aprobado' },
        { x: 650, y: 70, label: 'Listo para entrega' },
        { x: 500, y: 120, label: 'Rechazado' },
        { x: 350, y: 70, label: 'Radicado' },        
    ];
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const authUser = useAuthUser();
    const [filters, setFilters] = useState({
        "search" : ''
    })
    const navigate = useNavigate();
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [form_edit] = Form.useForm();
    const [form_create] = Form.useForm();
    const handlePageChange = page => {
        getData(page);
        setPage(page);
    };
    const [activeIndex, setActiveIndex] = useState(-1);
    const [colors, setColors] = useState(points.map(() => 'white'));

    const handleStatusChangeT = (index) => {
        console.log(index)
        setActiveIndex(index);
        let _color = "white"
        if(index === 3){
            _color = "green"
        }else if(index === 5){
            _color = "red"
        }else if(index === 4){
            _color = "green"
        }else{
            _color = "orange"
        }

        setColors((prevColors) =>
            //prevColors.map((color, i) => (i === index ? _color : color)) // Replace 'newColor' with the color you want to change to
            prevColors.map((color, i) =>
                i === index ? (color !== 'white' ? 'white' : _color) : color
            )
        );
    };

    const deleteTramite = () =>{

    }

    const showData = (data) =>{
        let _fields = {
            vehicle: data.vehicle+" - "+data.plate+" - "+data.proveedor,
            status: _marks.indexOf(data.status),
            id: data.id
        }
        setActiveIndex(_fields.status)
        switch (_fields.status) {
            case 0: //R. de documentos
                setColors(["orange", "white", "", "white", "white", "white", "white"])
                break;
            case 1: //enviado a traspaso
                setColors(["orange", "orange", "", "white", "white", "white", "white"])
                break;
            case 3: //Aprobado
                setColors(["orange", "orange", "", "green", "white", "white", "orange"])
                break;
            case 4: //Listado para entrega
                setColors(["orange", "orange", "", "green", "green", "white", "orange"])
                break;
            case 5: //Rechazado
                setColors(["orange", "orange", "", "white", "white", "red", "orange"])
                break;
            case 6: //radicado
                setColors(["orange", "orange", "", "white", "white", "white", "orange"])
                break;
            
            default:
                break;
        }
        form_edit.setFieldsValue(_fields);
        setIsEditOpen(true)
    }
    
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getData(page);    
        setLoading(false)
    };

    const getData = (ppage) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        TramitesService.getAll(ppage,limit,skip,_filter).then(resp =>{
            let _data = resp.data;
            if(authUser().rol == 4){
                console.log(authUser().proveedor)
                _data = _data.filter(x => x.proveedor == authUser().proveedor);
            }
            setTotalRows(resp.total);
            setData(_data);
            setLoading(false);
        })
    }

    const editCancel = () =>{
        form_edit.resetFields();
        setIsEditOpen(false)
        setActiveIndex(-1)
        setIsCreateOpen(false)
    }

    const editOk= () =>{
        form_edit
        .validateFields()
        .then((values) => {          
            let data = {
                "status": getMarca(),
                "id": values.id
            }
            
            TramitesService.update(data).then(resp =>{
                if(resp && resp.message.code === 200){
                    form_edit.resetFields();
                    getData(1);
                    editCancel();
                }
            })
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    const createCancel = () =>{
        form_create.resetFields();
        setColors(points.map(() => 'white'))
        setActiveIndex(-1)
        setIsCreateOpen(false)
    }

    const getMarca = () =>{
        if(colors[4] != 'white'){
            return 'Listado para entrega'
        }else if(colors[3] != 'white'){
            return 'Aprobado'
        }else if(colors[5] != 'white'){
            return 'Rechazado'
        }else if(colors[6] != 'white'){
            return 'Radicado'
        }else if(colors[1] != 'white'){
            return 'Enviado a traspaso'
        }else if(colors[0] != 'white'){
            return 'Recolección de documentos'
        }
    }

    const createOk= () =>{
        form_create
        .validateFields()
        .then((values) => {           
            let data = {
                tramite_estado: getMarca(),
                vehicle: values.vehicle,
                plate: values.plate,
                created_by: authUser().id,
                proveedor: values.proveedor
            }
            console.log(data)
            TramitesService.save(data).then(resp =>{
                console.log(resp)
                if(resp.message == 'duplicada'){
                    message.error("Ya existe un registro con esa placa");
                }else{
                    form_create.resetFields();
                    getData(1);
                    createCancel();
                }                
            })
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    const searchPlate = (event) =>{
        if(event.target.value.length === 6){
            ActasService.getByPlate(event.target.value).then(resp =>{
                let _data = resp.data
                if(_data.length > 0){
                    _data = _data[0]
                    let _fields = {
                        vehicle: _data.title
                    }
                    form_create.setFieldsValue(_fields)
                }
            })
        }
    }

    const saveField = (event) =>{
        let _filters = filters;
        switch (event.target.name) {
          case 'search':
            filters.search = event.target.value
            break;
          default:
            break;
        }
        setFilters(_filters)
        getData(1)
    }

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getData(page, limit, skip);
    }, []);

    return(
        <Row>
            <Modal title="Editar Trámite" width={800} open={isEditOpen} cancelText="Cancelar" onCancel={editCancel} onOk={editOk} okText={"Actualizar"}>
                <Form  form={form_edit} name="form_edit" layout='vertical'>
                    <Row className='mb-4'>
                        <Col span={24}>
                            <Form.Item label="Vehiculo" name="vehicle">
                                <Input type='text' placeholder='Vehiculo' disabled />
                            </Form.Item>
                            <Form.Item label="" name="id" hidden>
                                <Input type='hidden'/>
                            </Form.Item>
                        </Col>
                        <Col span={24} className='text-center'>
                            <svg width="700" height="200">
                            {points.map((point, index) => {
                                if (index === 0) return null; // No hay línea antes del primer punto                                
                                const prevPoint = points[index - 1];
                                return (
                                    <line
                                        key={index}
                                        x1={prevPoint.x}
                                        y1={prevPoint.y}
                                        x2={point.x}
                                        y2={point.y}
                                        stroke={"black"}
                                        strokeWidth="4"
                                    />
                                );
                            })}

                            {points.map((point, index) => (
                                <React.Fragment key={index}>
                                <circle
                                    cx={point.x}
                                    cy={point.y}
                                    r="10"
                                    fill={colors[index]}
                                    stroke={"black"}
                                    strokeWidth="2"
                                    onClick={() => handleStatusChangeT(index)}
                                    cursor="pointer"
                                />
                                <text
                                    x={point.x}
                                    y={point.y + 30}
                                    fontSize="12"
                                    textAnchor="middle"
                                >
                                    {point.label}
                                </text>
                                </React.Fragment>
                            ))}
                            </svg>
                        </Col> 
                    </Row>
                </Form>
            </Modal>
            <Modal title="Crear Trámite" width={800} open={isCreateOpen} cancelText="Cancelar" onCancel={createCancel} onOk={createOk} okText={"Guardar"}>
                <Form  form={form_create} name="form_create" layout='vertical'>
                    <CRow className='mb-4'>
                        <Col md={8} xs={24}>
                            <Form.Item label="Placa" name="plate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    }
                                ]}>
                                <Input type='text' placeholder='Ej: RNR549' onChange={ searchPlate } />
                            </Form.Item>
                            <Form.Item label="" name="vehicle_id" hidden>
                                <Input type='hidden'/>
                            </Form.Item>
                        </Col> 
                        <Col md={8} xs={24}>
                            <Form.Item label="Proveedor" name="proveedor"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    }
                                ]}>
                                <Select
                                    style={{ width: '100%' }}
                                    options={[
                                        { value: 'Vilecar', label: 'Vilecar' },
                                        { value: 'Zero', label: 'Zero' },
                                        { value: 'Otro', label: 'Otro' },
                                    ]}
                                />
                            </Form.Item>
                        </Col> 
                        <Col md={8} xs={24}>
                            <Form.Item label="Vehiculo" name="vehicle"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    }
                                ]}>
                                <Input type='text' placeholder='Ej: Mercedes-Benz'/>
                            </Form.Item>
                        </Col>
                    </CRow>
                    <CRow className='mb-4'>
                        <Col span={24} className='text-center'>
                        <svg width="700" height="200">
                            {points.map((point, index) => {
                                if (index === 0) return null; // No hay línea antes del primer punto                                
                                const prevPoint = points[index - 1];
                                return (
                                    <line
                                        key={index}
                                        x1={prevPoint.x}
                                        y1={prevPoint.y}
                                        x2={point.x}
                                        y2={point.y}
                                        stroke={"black"}
                                        strokeWidth="4"
                                    />
                                );
                            })}

                            {points.map((point, index) => (
                                <React.Fragment key={index}>
                                <circle
                                    cx={point.x}
                                    cy={point.y}
                                    r="10"
                                    fill={colors[index]}
                                    stroke={"black"}
                                    strokeWidth="2"
                                    onClick={() => handleStatusChangeT(index)}
                                    cursor="pointer"
                                />
                                <text
                                    x={point.x}
                                    y={point.y + 30}
                                    fontSize="12"
                                    textAnchor="middle"
                                >
                                    {point.label}
                                </text>
                                </React.Fragment>
                            ))}
                            </svg>
                        </Col>
                    </CRow>
                </Form>
            </Modal>
            <Col span={24} className='text-center my-4'>
                <h3>Estado de trámites</h3>
            </Col>
            <Col span={24} className='text-end'>
                <Link onClick={ () => setIsCreateOpen(true) } className='btn btn-primary'>Crear</Link>
            </Col>
            <Col span={24} className='text-end'>
                    <div className='form-group w-100 text-end'>
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <input type="search" className='form-control m-150' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
                        </span>
                    </div>
            </Col>
            <Col span={24} className='my-4'>
                <DataTable
                    noDataComponent={"No se han encontrado resultados"}
                    columns={ columns }
                    data={data}
                    progressPending={loading}
                    pagination 
                    paginationServer
                    responsive             
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            </Col>
        </Row>
    )
}

export default Index;
