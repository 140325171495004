import API from "./Config";
import ENDPOINTS from "./Enpoints";

const ClientesService = {
    getAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("clients?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    getAllClients: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.CLIENTES+"s/all")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    get: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.CLIENTES+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    delete: (id) => new Promise((resolve, reject) => {
        API.delete(ENDPOINTS.CLIENTES+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.CLIENTES, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    update: (data, id) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.CLIENTES+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    })
}

export default ClientesService;
