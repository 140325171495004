import React, {useState, useEffect} from 'react'
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component'
import Moment from 'moment'
import PruebaRutasService from 'src/services/PruebaRutasService';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";

const Index = () => {
    const columns = [
        {
            name: 'Fecha',
            selector: rowData => Moment(rowData.created_at).format("DD/MM/YYYY h:mm:ss a")
        }, 
        {
            name: 'Cliente',
            selector: rowData => rowData.nombre+" "+rowData.apellido
        },
        {
            name: 'Correo',
            selector: rowData => rowData.correo
        },
        {
            name: 'Teléfono',
            selector: rowData => rowData.celular
        },
        {
            name: 'Licencia Nro',
            selector: rowData => rowData.licencia
        },
        {
            name: 'Vehículo',
            selector: rowData => rowData.modelo_marca
        },
        {
            name: 'Placa',
            selector: rowData => <strong>{rowData.placa.toUpperCase()}</strong>
        },
        {
            name: 'Creado por',
            selector: row => row.user,
            sortable: true,
        }, 
        {
            name: 'Enlace',
            selector: row => <a rel="noreferrer" href={`https://intranet.triplo.com.co/pdfs/Prueba_de_ruta_${row.placa}.pdf`} target='_blank'>Descargar PDF</a>
        }
    ];
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const authUser = useAuthUser();
    const [filters, setFilters] = useState({
        "search" : ''
    })
    const navigate = useNavigate();

    const handlePageChange = page => {
        getData(page);
        setPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getData(page);    
        setLoading(false)
    };

    const getData = (ppage) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        PruebaRutasService.getAll(ppage,limit,skip,_filter).then(resp =>{
            let _data = resp.data;
            setTotalRows(resp.total);
            setData(_data);
            setLoading(false);
        })
    }

    const saveField = (event) =>{
        let _filters = filters;
        switch (event.target.name) {
          case 'search':
            filters.search = event.target.value
            break;
          default:
            break;
        }
        setFilters(_filters)
        getData(1)
    }

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getData(page, limit, skip);
    }, []);

    return(
        <>
            <Row>
                <Col span={24} className='text-center my-4'>
                    <h3>Listado de pruebas de ruta</h3>
                </Col>
                <Col span={24} className='text-end'>
                    <Link to={"/prueba-ruta/crear"} className='btn btn-primary me-3'>Crear prueba de ruta</Link>
                    <Link to={'https://intranet.triplo.com.co/pdfs/Prueba_De_Ruta.pdf'} target='_blank' className='btn btn-secondary'>Formato en blanco</Link>
                </Col>
                <Col span={24} className='text-end'>
                    <div className='form-group w-100 text-end'>
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <input type="search" className='form-control m-150' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
                        </span>
                    </div>
                </Col>
                <Col span={24} className='my-4'>
                    <DataTable
                        noDataComponent={"No se han encontrado resultados"}
                        columns={ columns }
                        data={data}
                        progressPending={loading}
                        pagination 
                        paginationServer
                        responsive             
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Index;
