import React, {useState, useEffect} from 'react'
import { Col, Row, Modal, Form, Input, Select } from 'antd';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component'
import Moment from 'moment'
import BonosService from 'src/services/BonosService';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import Alianzas from './alianzas';
import { FormOutlined } from '@ant-design/icons';

const Index = () => {
    const columns = [
        {
            name: 'Alianza',
            selector: rowData => rowData.alianza
        }, 
        {
            name: 'Cliente',
            selector: rowData => rowData.nombres
        },
        {
            name: 'Consecutivo',
            selector: rowData => rowData.consecutivo
        },
        {
            name: 'Fecha de vencimiento',
            selector: rowData => Moment(rowData.vencimiento).format("DD/MM/YYYY")
        },
        {
            name: 'Creado por',
            selector: rowData => rowData.usuario
        },
        {
            name: 'Actualizado el',
            selector: rowData => Moment(rowData.created_at).format("DD/MM/YYYY h:mm:ss a")
        },
        {
            name: "PDF",
            selector: rowData => <a href={`https://intranet.triplo.com.co/pdfs/Bono_${rowData.alianza_pdf}_${rowData.consecutivo}.pdf`} target="_blank">Click aqui!</a>
        },
        {
            name: 'Acciones',
            selector: rowData => {
              return <div className='d-flex justify-content-between'>
                  <div className='text-center cc1' onClick={ () => showData(rowData) }>
                    <FormOutlined className='iconCameraTable2' title='Editar'/>
                  </div>
              </div>           
            }
        }
    ];
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [alianza, setAlianza] = useState("");
    const [loading, setLoading] = useState(false);
    const [alianzas, setAlianzas] = useState([]);
    const authUser = useAuthUser();
    const [filters, setFilters] = useState({
        "search" : ''
    })
    const [form_edit_bono] = Form.useForm();
    const [form_create_bono] = Form.useForm();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const showData = (data) =>{
        let _fields = {
            bono_id: data.id,
            nombres: data.nombres,
            cedula: data.cedula ? data.cedula : '-',
            placa: data.placa ? data.placa : '-',
            vencimiento: Moment(data.vencimiento).format("YYYY-MM-DD"),
            consecutivo: data.consecutivo,
            alianza: data.alianza
        }
        form_edit_bono.setFieldsValue(_fields);
        setIsModalOpen(true);
    } 
    const handlePageChange = page => {
        getData(page);
        setPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getData(page);    
        setLoading(false)
    };

    const getData = (ppage) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        BonosService.getAll(ppage,limit,skip,_filter).then(resp =>{
            let _data = resp.data;
            setTotalRows(resp.total);
            setData(_data);
            setLoading(false);
        })
    }

    const getAlianzas = () =>{
        BonosService.getAlianzasList().then(resp =>{
            setAlianzas(resp.data)
        })
    }
    const handleCreateOk = () =>{
        form_create_bono
        .validateFields()
        .then((values) => {               
            let data = {
                "nombres": values.nombres,
                "vencimiento": values.vencimiento,
                "alianza_id": values.alianza,
                "consecutivo": values.consecutivo,
                "consecutivo_id": values.consecutivo_id,
                "cedula": values.cedula,
                "placa": values.placa,
                "alianza": alianza, 
                "user_id": authUser().id
            }
            BonosService.save(data).then(resp =>{
                if(resp && resp.message.code === 200){
                    form_create_bono.resetFields();
                    getData(1);
                    setIsModalCreateOpen(false);
                }
            })
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    const handleCreateCancel = () => {
        form_create_bono.resetFields();
        setIsModalCreateOpen(false);
    };

    const handleOk = () => {
        form_edit_bono
        .validateFields()
        .then((values) => {     
            let data = {
                "nombres": values.nombres,
                "vencimiento": values.vencimiento,
                "id": values.bono_id
            }
            BonosService.updateBono(data).then(resp =>{
                //if(resp && resp.message.code === 200){
                    form_edit_bono.resetFields();
                    getData(1);
                    setIsModalOpen(false);
                //}
            })
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    };

    const handleCancel = () => {
        form_edit_bono.resetFields();
        setIsModalOpen(false);
    };

    const selectAlianza = () => {
        BonosService.getConsecutivo(form_create_bono.getFieldValue('alianza')).then(resp =>{
            let _data = resp.data[0]
            let _vencimiento = Moment(new Date()).add(_data.vencimiento_dias,'days').format("YYYY-MM-DD")
            setAlianza(_data.alianza)
            let _fields = {
                consecutivo: _data.nombre,
                consecutivo_id:_data.id ,
                vencimiento: _vencimiento              
            }
            form_create_bono.setFieldsValue(_fields)
        })
    }

    const createBono = () =>{
        setIsModalCreateOpen(true)
    }

    const saveField = (event) =>{
        let _filters = filters;
        switch (event.target.name) {
          case 'search':
            filters.search = event.target.value
            break;
          default:
            break;
        }
        setFilters(_filters)
        getData(1)
    }

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getData(page, limit, skip);
        getAlianzas();
    }, []);

    return(
        <Row>
            <Modal title="Editar Bono" open={isModalOpen} cancelText="Cerrar" onCancel={handleCancel} onOk={handleOk} okText={"Aceptar"}>
                <Form  form={form_edit_bono} name="form_edit_bono" layout='vertical'>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="" name="bono_id" >
                                <Input type='hidden' />
                            </Form.Item>
                            <Form.Item label="Nombre" name="nombres"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    }
                                ]}>
                                <Input type='text' placeholder='Nombre' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Consecutivo" name="consecutivo">
                                <Input type='text' placeholder='Consecutivo' disabled />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Alianza" name="alianza">
                                <Input type='text' placeholder='Alianza' disabled />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Fecha de vencimiento" name="vencimiento"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    }
                                ]}>
                                <Input type='date' placeholder='Fecha de vencimiento' />
                            </Form.Item>
                        </Col>  
                    </Row>
                </Form>
            </Modal>
            <Modal title="Crear Bono" open={isModalCreateOpen} cancelText="Cancelar" onCancel={handleCreateCancel} onOk={handleCreateOk} okText={"Crear"}>
                <Form  form={form_create_bono} name="form_create_bono" layout='vertical'>
                    <Row>                    
                        <Col span={24}>
                            <Form.Item label="Nombres y apellidos" name="nombres"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    }
                                ]}>
                                <Input type='text' placeholder='Nombres y apellidos' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Seleccione la alianza" name="alianza" 
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es requerido!',
                                    },
                                ]}>
                                <Select options={alianzas} onChange={ selectAlianza }></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Cédula" name="cedula">
                                <Input type='text' placeholder='Cédula' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Placa" name="placa">
                                <Input type='text' placeholder='Ej: XXX123' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Fecha de vencimiento" name="vencimiento">
                                <Input type='date' placeholder='Fecha de vencimiento' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Consecutivos" name="consecutivo">
                                <Input type='text' placeholder='JP513' disabled/>
                            </Form.Item>
                            <Form.Item label="" name="consecutivo_id">
                                <Input type='hidden'/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Col span={24}>
                <div className="card mx-2 ">
                    <div className="card-body">
                        <Row>
                            <Col span={24} className='text-center my-4'>
                                <h3>Listado de Bonos</h3>
                            </Col>
                            <Col span={24} className='text-end'>
                                <Link onClick={ createBono } className='btn btn-primary'>Crear Bono</Link>
                            </Col>
                            <Col span={24} className='text-end'>
                                <div className='form-group w-100 text-end'>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <input type="search" className='form-control m-150' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
                                    </span>
                                </div>
                            </Col>
                            <Col span={24} className='my-4'>
                                <DataTable
                                    noDataComponent={"No se han encontrado resultados"}
                                    columns={ columns }
                                    data={data}
                                    progressPending={loading}
                                    pagination 
                                    paginationServer
                                    responsive             
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default Index;
