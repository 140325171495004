import API from "./Config";
import ENDPOINTS from "./Enpoints";

const DriverService = {
    getAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("driver?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    get: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.DRIVER+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    delete: (id) => new Promise((resolve, reject) => {
        API.delete(ENDPOINTS.DRIVER+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    deletePhoto: (id) => new Promise((resolve, reject) => {
        API.delete(ENDPOINTS.DRIVER+"delete-photo/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getToken: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.ONEDRIVE+"s/token")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.DRIVER, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    update: (data, id) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.DRIVER+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    savePhoto: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.DRIVER+"photo", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    saveDireccion: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.DRIVER+"direccion", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    finalizar: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.DRIVER+"finalizar", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    sendMsg: (data) => new Promise((resolve, reject) => {
      API.post(ENDPOINTS.DRIVER+"/c/msg", data)
      .then(
          res => res.data
      )
      .then(
          data => resolve(data)
      )
      .catch(
          err => reject(err)
      )
    }),
    getByPlaca: (placa) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.DRIVER+"/c/get-by-id/"+placa)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
}

export default DriverService;
