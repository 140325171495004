import API from "./Config";
import ENDPOINTS from "./Enpoints";

const BonosService = {
    getAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("bonos?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    getAlianzas:(page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("bonos/alianza?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    getAlianzasList:() => new Promise((resolve, reject) => {
        API.get("bonos/alianza-listado")
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    getConsecutivo: (alianza) => new Promise((resolve, reject) => {
        API.get("bonos/buscar-consecutivo/"+alianza)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.BONOS, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    saveAlianza: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.BONOS+"/alianza", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    saveConsecutivos: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.BONOS+"/alianza-consecutivos", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    updateBono: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.BONOS+"/alianza-bono", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    })
}

export default BonosService;
