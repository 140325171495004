import React ,{ useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import RetomasService from "src/services/Retoma";
import Moment from 'moment'
import DataTable from 'react-data-table-component'

const PartePago = () => {
    const navigate = useNavigate();
    const [price, setPrice] = useState(138); // Estado para el precio
    const [financePercent, setFinancePercent] = useState(65); // Estado para el porcentaje financiado
    const [currentStep, setCurrentStep] = useState(1); // Paso actual del formulario
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    
    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handleFinancePercentChange = (newValue) => {
        setFinancePercent(newValue);
    };

    const volver = () => {
        navigate('/car-market');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Precio acordado:", price);
        console.log("Porcentaje financiado:", financePercent);
    };

    const nextButton = () => {
        setCurrentStep(currentStep + 1);
    };

    const getStep = () => {
        
    };

    const handlePageChange = page => {
        getData(page);
        setPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getData(page);    
        setLoading(false)
    };
    
    const columns = [
        {
          name: 'Fecha',
          selector: rowData => Moment(rowData.created_at).format("DD/MM/YYYY h:mm:ss a")
        },    
        {
          name: 'Vehículo a retomar',
          selector: row => row.vehiculo_retoma,
          sortable: true,
        },
        {
          name: 'Vehículo en venta',
          selector: row => row.title,
          sortable: true,
        },
        {
          name: 'Estado',
          selector: rowData => <span className={'badge rounded-pill text-bg-success'}>{rowData.estado.toUpperCase()}</span>,
          sortable: true
        },       
        {
          name: 'Acciones',
          selector: rowData => { <div></div>}
        }
    ];

    const getData = () =>{
        let _filter = '';
        RetomasService.getRetomas().then(resp =>{
            let _data = resp.data;
            setData(_data);
        })
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="car-finance-form contado">
            <h2>LNL459 (placa del carro) { currentStep }</h2>

            {
                !isNext ?
                <form className="car-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>1. Indique la oferta de retoma</label>
                        <DataTable
                            noDataComponent={"No se han encontrado resultados"}
                            columns={ columns }
                            data={data}
                            pagination 
                        />
                    </div>
                </form> :
                <div>
                    <div className="form-group">
                        <label>1. Indique el precio acordado del vehículo</label>
                    </div>
                    <div className="form-group">
                        <label>2. Indique la informacion del vehiculo </label>
                    </div>
                    <div className="form-group">
                        <label>3. Indique el metodo de pago del restante</label>
                        <button className="btn btn-primary btn3 w-100">Contado</button>
                        <button className="btn btn-primary btn3 w-100">Contado y Credito</button>
                        <button className="btn btn-primary btn3 w-100">Credito</button>
                    </div>
                </div>
            }
            
        </div>
    );
};

export default PartePago;
