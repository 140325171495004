import { CRow } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import MakeService from 'src/services/MakeService';
import ModelService from 'src/services/ModelService';
import YearService from 'src/services/YearService';
import VersionService from 'src/services/VersionService';
import VehiculosService from 'src/services/VehiculosService';
import { useNavigate  } from "react-router-dom";
import { Col, Form, Select, Input, Checkbox, Slider, Button, Upload, Image, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useAuthUser } from 'react-auth-kit';
import axios from 'axios';
const { TextArea } = Input;

const estados = [
    {value: 'Bueno', label: 'Bueno'},
    {value: 'Muy Bueno', label: 'Muy Bueno'},
    {value: 'Excelente', label: 'Excelente'}
];
const duenos = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4 o mas', label: '4 o mas'},
    {value: 'No se sabe', label: 'No se sabe'},
];

const marks = {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
};

const CreateCar = () =>{
    const [carData, setCarData] = useState({});
    const [makes, setMakes] = useState([]);
    const [years, setYears] = useState([]);
    const [models, setModels] = useState([]);
    const [versions, setVersions] = useState([]);
    const [prenda, setPrenda] = useState(false);
    const [asegurado, setAsegurado] = useState(false);
    const [mantenimiento, setMantenimiento] = useState(false);
    const navigate = useNavigate();
    const [imageFront, setImageFront] = useState('https://cars-triplo-back.s3.amazonaws.com/sinimagen.jpg');
    const [imageBack, setImageBack] = useState('https://cars-triplo-back.s3.amazonaws.com/sinimagen.jpg');
    const [form] = Form.useForm();
    const authUser = useAuthUser();
    const [marca, setMarca] = useState('')
    const [modelo, setModelo] = useState('')
    const [anho, setAnho] = useState('')
    const [version, setVersion] = useState('')
    
    const getMakes = () =>{
        MakeService.getAll()
        .then(data => {
            let arrayOriginal = data.data;
            const nuevoArray = arrayOriginal.map(item => ({
                label: item.name,
                value: item.id
            }));
            setMakes(nuevoArray)
        })
        .catch( error => console.log(error));
    }

    const save = () =>{
    }

    const onChangeMarca = (value) => {
        value = value[0]
        setMarca(value)
        if(typeof value == 'number'){
            YearService.getYearByMake(value)
            .then(data => {
                let arrayOriginal = data.data;
                const nuevoArray = arrayOriginal.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setYears(nuevoArray);
            })
            .catch( error => console.log(error));
        }
    };

    const onChangeYear = (value) => {
        value = value[0]
        setAnho(value)
        if(typeof value == 'number'){
            ModelService.getModelsByYear(value)
            .then(data => {
                let arrayOriginal = data.data;
                const nuevoArray = arrayOriginal.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setModels(nuevoArray);
            })
            .catch( error => console.log(error));
        }
    };

    const onChangeModel = (value) => {
        value = value[0]
        setModelo(value)
        if(typeof value == 'number'){
            VersionService.getVersionsByModel(value)
            .then(data => {
                let arrayOriginal = data.data;
                const nuevoArray = arrayOriginal.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setVersions(nuevoArray);
            })
            .catch( error => console.log(error));
        }
    };

    const onChangeVersion = (value) => {
        setVersion(value[0])
    }

    const uploadImage = async options => {
        const { onSuccess, onError, file } = options;
        var fmData = new FormData() 
        fmData.append("file", file);
        try {
            const res = await axios.post(
              'https://back.triplo.com.co/api/v1/uploads3/recepcion_'+form.getFieldValue('plate'),
              fmData
            );
            setImageFront(res.data.uri) 
            message.success(`Foto frontal de tarjeta de propiedad subida con éxito`);     
            onSuccess("Ok");
            console.log("server res: ");
        } catch (err) {
            console.log("Eroor: ", err);
            message.error(`Error al subir la foto frontal de tarjeta de propiedad`);
            onError({ err });
        }
    }

    const uploadImageBack = async options => {
        const { onSuccess, onError, file } = options;
        var fmData = new FormData() 
        fmData.append("file", file);
        try {
            const res = await axios.post(
              'https://back.triplo.com.co/api/v1/uploads3/recepcion_'+form.getFieldValue('plate'),
              fmData
            );
            setImageBack(res.data.uri)  
            message.success(`Foto posterior de tarjeta de propiedad subida con éxito`);    
            onSuccess("Ok");
            console.log("server res: ");
        } catch (err) {
            console.log("Eroor: ", err);
            message.error(`Error al subir la foto posterior de tarjeta de propiedad`);
            onError({ err });
        }
    }

    const saveCar = (values) =>{
        console.log(values)
        console.log(marca)
        console.log(anho)
        console.log(modelo)
        console.log(version)

        let brand = typeof marca == 'number' ? makes.filter(x => x.value == values.make) : marca;
        let model = typeof modelo == 'number' ? models.filter(x => x.value == values.model) : modelo;
        let year =  typeof anho == 'number' ? years.filter(x => x.value == values.year) : anho;
        let vversion = typeof version == 'number' ? versions.filter(x => x.value == values.version) : version;

        const newData = {
            pdf: "https://intranet.triplo.com.co/pdfs/Tarjeta_de_propiedad_"+values.plate+".pdf",
            ttarjeta_propiedad_frente: imageFront,
            ttarjeta_propiedad_atras: imageBack,
            user_id: authUser().id,
            title: marca+' '+modelo+' '+anho,
            mmake: marca,
            yyear: anho,
            mmodel: modelo,
            vversion: vversion,
            ...values
        }
        VehiculosService.createDataNew(newData).then(resp =>{
            let data = resp.id;
            navigate('/vehiculos/editar/'+data)
        })
    }

    useEffect(() => {
        getMakes();
    }, [])

    return(
        <>
            <h3>Información del vehículo Nuevo</h3>
            <hr />
            <Form layout='vertical' onFinish={ saveCar } form={form}>
                <CRow>
                    <Col span={8}>
                        <Form.Item label="Marca" name="make"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Select
                                showSearch
                                placeholder="Seleccione una marca"
                                optionFilterProp="label"
                                onChange={ onChangeMarca }
                                options={ makes }
                                mode="tags"
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Año" name="year"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Select
                                showSearch
                                placeholder="Seleccione un año"
                                optionFilterProp="label"
                                onChange={ onChangeYear }
                                options={ years }
                                mode="tags"
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Modelo" name="model"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Select
                                showSearch
                                placeholder="Seleccione un modelo"
                                optionFilterProp="label"
                                onChange={ onChangeModel }
                                options={ models }
                                mode="tags"
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={8}>
                        <Form.Item label="Versión" name="version"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Select
                                showSearch
                                placeholder="Seleccione una versión"
                                optionFilterProp="label"
                                options={ versions }
                                mode="tags"
                                onChange={onChangeVersion}
                                maxCount={1}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Placa" name="plate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' onInput={e => e.target.value = e.target.value.toUpperCase()} placeholder='Ej: XXX111' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Kilometraje" name="kilometers" 
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 5000' />
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={8}>
                        <Form.Item label="Precio" name="price">
                            <Input type='text' placeholder='Ej: 4000000' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Motor" name="motor">
                            <Input type='text' placeholder='Ej: 1234' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Chasis" name="chasis">
                            <Input type='text' placeholder='Ej: 1234' />
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={8}>
                        <Form.Item label="Valor SOAT" name="valor_soat">
                            <Input type='text' placeholder='Ej: 1234' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Valor Impuesto" name="valor_impuesto">
                            <Input type='text' placeholder='Ej: 1234' />
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={8}>
                        <Form.Item label="Estado del vehículo" name="estado_general"
                            rules={[
                                {
                                    required: true,
                                    message: 'El estado es requerido!',
                                },
                            ]}>
                            <Select options={ estados } showSearch placeholder="Seleccione"></Select>
                        </Form.Item> 
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Nro de dueños" name="nro_duenos"
                            rules={[
                                {
                                    required: true,
                                    message: 'El nro de dueños es requerido!',
                                },
                            ]}>
                            <Select options={ duenos } showSearch placeholder="Seleccione"></Select>
                        </Form.Item> 
                    </Col>
                    <Col span={8}>
                        <Form.Item label="¿Cuántas llaves tiene?"  name="segunda_llave"
                            rules={[
                                {
                                    required: true,
                                    message: 'La segunda llave es requerida!',
                                }
                            ]}>
                            <Input type='text'placeholder='Segunda llave' />
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={8}>
                        <Form.Item label="" name="kit_carretera" valuePropName="checked">
                            <Checkbox>¿Tiene kit de carretera?</Checkbox>
                        </Form.Item> 
                    </Col>
                    <Col span={8}>
                        <Form.Item label="" name="llanta_repuesto" valuePropName="checked">
                            <Checkbox>¿Tiene llanta de repuesto?</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="" name="perno_seguridad" valuePropName="checked">
                            <Checkbox>¿Tiene perno de seguridad?</Checkbox>
                        </Form.Item> 
                    </Col>
                </CRow>
                <CRow>
                    <Col span={8}>
                        <Form.Item label="" name="herramientas" valuePropName="checked">
                            <Checkbox>¿Tiene herramientas?</Checkbox>
                        </Form.Item> 
                    </Col>
                    <Col span={8}>
                        <Form.Item label="" name="gato" valuePropName="checked">
                            <Checkbox>¿Tiene gato?</Checkbox>
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={8}>                        
                        <Form.Item label="" name="prenda" valuePropName="checked" onChange={ () => setPrenda(!prenda)}>
                            <Checkbox>¿Tiene prenda?</Checkbox>
                        </Form.Item>   
                        {
                            prenda ? 
                            <Form.Item label="Observación de prenda" name="prenda_obs">
                                <TextArea rows={2} />
                            </Form.Item> : ''
                        }  
                                            
                    </Col>
                    <Col span={8}>                        
                        <Form.Item label="" name="asegurado" valuePropName="checked" onChange={ () => setAsegurado(!asegurado)}>
                            <Checkbox>¿Está asegurado?</Checkbox>
                        </Form.Item>   
                        {
                            asegurado ? 
                            <Form.Item label="Observación de asegurado" name="asegurado_obs">
                                <TextArea rows={2} />
                            </Form.Item> : ''
                        }                      
                    </Col>
                    <Col span={8}>                        
                        <Form.Item label="" name="mantenimiento" valuePropName="checked" onChange={ () => setMantenimiento(!mantenimiento)}>
                            <Checkbox>¿Mantenimiento?</Checkbox>
                        </Form.Item> 
                        {
                            mantenimiento ? 
                            <Form.Item label="Observación de mantenimiento" name="mantenimiento_obs">
                                <TextArea rows={2} />
                            </Form.Item> : ''
                        }                        
                    </Col>
                </CRow>
                <CRow>
                    <Col span={8}>                        
                        <Form.Item label="Estado ruedas delanteras(%)" name="estado_llanta_del">
                            <Slider marks={marks} defaultValue={50} min={0} max={100} step={10} tooltip={{ open: true }} />
                        </Form.Item>                         
                    </Col>
                    <Col span={8}>                        
                        <Form.Item label="Estado ruedas traseras(%)" name="estado_llanta_tra">
                            <Slider marks={marks} defaultValue={50} min={0} max={100} step={10} tooltip={{ open: true }} />
                        </Form.Item>                         
                    </Col>
                </CRow>
                <CRow>
                    <Col span={24}>
                        <Form.Item label="Detalles para publicar" name="detalles">
                            <TextArea rows={4} /> 
                        </Form.Item>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col span={24}>
                        <Form.Item label="Observaciones" name="observaciones">
                            <TextArea rows={4} /> 
                        </Form.Item>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col span={8}>
                        <Image width={200} src={imageFront} placeholder="Ver"/>
                        <Form.Item label="Foto frontal de tarjeta de propiedad"  name="tarjeta_propiedad_frente">
                            <Upload customRequest={uploadImage}  accept="image/*">
                                <Button icon={<UploadOutlined />}>Click para cargar</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Image width={200} src={imageBack} placeholder="Ver"/>
                        <Form.Item label="Foto posterior de tarjeta de propiedad"  name="tarjeta_propiedad_atras">
                            <Upload customRequest={uploadImageBack}  accept="image/*">
                                    <Button icon={<UploadOutlined />}>Click para cargar</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col span={24} className='text-center'>
                        <Form.Item>
                            <Button type='primary'  htmlType="submit">Guardar</Button>
                        </Form.Item>
                    </Col>
                </CRow>
            </Form>
        </>
    )
}
  
export default CreateCar;